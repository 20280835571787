<template>
  <v-col cols="12" sm="6" md="6">
    <v-text-field
      :value="value"
      label="Quote Number"
      readonly
      single-line
      outlined
      dense
      color="#3d2cdd"
      class="font-weight-bold"
      :loading="loading"
    >
    </v-text-field>
  </v-col>
</template>
<script>
export default {
  name: "QuoteNumberInput",
  props: ["value", "loading"],
  data() {
    return {};
  },
};
</script>